import { Component, Vue } from 'vue-property-decorator'

//theme components
import PublicNavbar from '@/components/publicNavbar/PublicNavbar.vue'
import Footer from '@/components/footer/Footer.vue';

import { SizedBox } from '@/components/wrappers';

@Component({
	components:{
		PublicNavbar,
		SizedBox,
		Footer
	}
})
export default class PublicContent extends Vue { }
